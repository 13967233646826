import { createRoot } from 'react-dom/client'

import { Canvas } from '@react-three/fiber'
import App from './App.jsx'
import { StrictMode, useRef } from 'react'
import { BrowserRouter } from 'react-router-dom'

const root = createRoot(document.querySelector('#root'))

root.render(
    <StrictMode>
        <BrowserRouter>
            <Canvas>
                <App />
            </Canvas>
        </BrowserRouter>
    </StrictMode>
)

