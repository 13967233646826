import { Text, useGLTF, useTexture } from "@react-three/drei"
import { useEffect, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { EffectComposer, Outline, Select, Selection } from "@react-three/postprocessing";
import { precomputedProjectsPositions } from "../precomputedPositions";
import { projectsDetails } from "./data/projectDetails";

const {
    projectsText: projectsTextPositions, 
    worldMap: worldMapPositions, 
    plane: planePositions, 
    arcadeMachine: arcadeMachinePositions,
    inventoryManagement: inventoryManagementPositions,
    screen: screenPositions,
    whiteScreen: whiteScreenPositions,
    whiteScreenSize,
    titleText: titleTextPositions,
    descriptionStartingText: descriptionStartingTextPositions,
    descriptionStartingTextParagraphDistance,
    skillsetText: skillsetTextPositions,
    skillsetItem: skillsetItemPositions,
    skillsetItemParagraphDistance,
    languagesText: languagesTextPositions,
    languagesItem: languagesItemPositions,
    languagesItemParagraphDistance,
    viewProjectArrowText: viewProjectArrowTextPositions,
    viewProjectLabelTextOne: viewProjectLabelTextOnePositions,
    viewProjectLabelTextTwo :viewProjectLabelTextTwoPositions

} = precomputedProjectsPositions


const {
    DNSAIProject: DNSAIProjectDetails,
    TrajectoryProject: TrajectoryProjectDetails,
    ArcadeMachineProject: ArcadeMachineProjectDetails,
    InventoryManagementProject: InventoryManagementProjectDetails
} = projectsDetails

export default function ProjectsSection({setMousePointerIcon , screenOn, settings}) {

    const { themeColour, emphasisColour, deemphasisColour, linkColours } = settings.colours
    const { defaultColour, highlightColour} = linkColours

    const {InterBoldFontPath, InterSemiBoldFontPath} = settings.publicFilePaths.fonts

    const {
        arcadeMachine: arcadeMachineModelPath, arcadeMachineTexture: arcadeMachineTexturePath,
        inventoryManagement: inventoryManagementModelPath, inventoryManagementTexture: inventoryManagementTexturePath,
        plane: planeModelPath, planeTexture: planeTexturePath,
        worldMap: worldMapModelPath, worldMapTexture: worldMapTexturePath,
        screen: screenPath,
    } = settings.publicFilePaths.models.projectsSection


    const { floatSpeed, 
        floatHeight, 
        hiddenEdgeColor, 
        edgeStrength,
        titleFontSize,
        semiTitleFontSize,
        linkFontSize,
        contentFontSize,
        textLineMaxWidth,
        textLineHeight
    
    } = settings.projectsSection


    const { nodes: screenNodes } = useGLTF(screenPath);

    const { nodes: arcadeMachineNodes } = useGLTF(arcadeMachineModelPath);
    const arcadeTexture = useTexture(arcadeMachineTexturePath);
    arcadeTexture.flipY = false;

    const { nodes: inventoryManagementNodes } = useGLTF(inventoryManagementModelPath);
    const inventoryManagementTexture = useTexture(inventoryManagementTexturePath);
    inventoryManagementTexture.flipY = false;

    const { nodes: worldMapNodes } = useGLTF(worldMapModelPath);
    const worldMapTexture = useTexture(worldMapTexturePath);
    worldMapTexture.flipY = false;

    const { nodes: planeNodes } = useGLTF(planeModelPath);
    const planeTexture = useTexture(planeTexturePath);
    planeTexture.flipY = false;

    const worldMapReference = useRef();
    const planeReference = useRef();
    const arcadeMachineReference = useRef();
    const inventoryManagementReference = useRef();
    const detailsScreenReference = useRef();
    const titleContentReference = useRef();
    
    const televisionScreenReference = useRef();
    const viewMoreReference = useRef();

    const [enabledWorldMap, setEnabledWorldMap] = useState(false);
    const [enabledPlane, setEnabledPlane] = useState(false);
    const [enabledArcadeMachine, setEnableArcadeMachine] = useState(false);
    const [enabledInventoryManagementReference, setEnabledInventoryManagementReference] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false)
    const [projectTitle, setProjectTitle] = useState("")
    const [titleLinkPath, setTitleLinkPath] = useState("")
    const [descriptionText, setDescriptionText] = useState([])
    const [skillsetsUsed, setSkillsetsUsed] = useState([])
    const [languagesUsed, setLanguagesUsed] = useState([])
    const [isLinkHovered, setIsLinkedHovered] = useState(false)
    const [enabledLinks, setEnabledLinks] = useState(false)

    useEffect(() => {
        if (screenOn) {
            setShowInstructions(true)
            detailsScreenReference.current.position.set(0,0,0)
        } else {
            setShowInstructions(false)
            detailsScreenReference.current.position.set(1,0,0)
        }
    }, [screenOn]);

    const setAllSelectionToFalse = () => {
        setEnabledWorldMap(false)
        setEnabledPlane(false)
        setEnableArcadeMachine(false)
        setEnabledInventoryManagementReference(false)
        
        setShowInstructions(true)
    }
    const setSelectionToTrue = (reference) => {
        setShowInstructions(false)
        if (reference === worldMapReference.current) {
            setEnabledWorldMap(true);
            setEnabledLinks(true)
        } else if (reference === planeReference.current) {
            setEnabledPlane(true);
            setEnabledLinks(true)
        } else if (reference === arcadeMachineReference.current) {
            setEnableArcadeMachine(true);
            setEnabledLinks(true)
        } else if (reference === inventoryManagementReference.current) {
            setEnabledInventoryManagementReference(true);
            setEnabledLinks(false)

        }
    }

    const setDetails = (reference) => {
        if (reference === worldMapReference.current) {
            setDetailsText(DNSAIProjectDetails)
        } else if (reference === planeReference.current) {
            setDetailsText(TrajectoryProjectDetails)
        } else if (reference === arcadeMachineReference.current) {
            setDetailsText(ArcadeMachineProjectDetails)
        } else if (reference === inventoryManagementReference.current) {
            setDetailsText(InventoryManagementProjectDetails)
        } else {
            setDetailsText(Instructions)
        }
    };

    const setDetailsText = (projectDetails) => {
        const {title, link, description, skillsets, languages} = projectDetails
        setProjectTitle(title)
        setTitleLinkPath(link)
        setDescriptionText(description)
        setSkillsetsUsed(skillsets)
        setLanguagesUsed(languages)
    
    }


    const onHoverOverride = (reference) => {

            setAllSelectionToFalse();
            setMousePointerIcon(true);
        
            setSelectionToTrue(reference.current);
            setDetails(reference.current);

    }

    const meshReferences = useRef();
    useFrame(({ clock }) => {
        const time = clock.getElapsedTime();

        meshReferences.current.position.y = Math.sin(time * floatSpeed) * floatHeight;
    });

    
    const handleHyperlinkClick = () => {
        if (titleLinkPath && enabledLinks) {

            window.open(titleLinkPath, '_blank');
        }
    };

    const handlePointerEnter = () => {
        if (enabledLinks) {
            if (titleContentReference.current) {
                titleContentReference.current.color = highlightColour
            }
            setIsLinkedHovered(true)
            setMousePointerIcon(true);
        }

    };

    const handlePointerLeave = () => {
        if (titleContentReference.current) {
            titleContentReference.current.color = defaultColour
        }
        setIsLinkedHovered(false)
        setMousePointerIcon(false);
    };

    useFrame((state) => {
        if (viewMoreReference.current) {
            if (isLinkHovered) {
                viewMoreReference.current.visible = true;
            } else {
                if (state.clock.getElapsedTime() % 1 < 0.5) {
                    viewMoreReference.current.visible = true;
                } else {
                    viewMoreReference.current.visible = false;
                }
            }
            viewMoreReference.current.sync();
        }
    });

    useEffect(() => {
        return () => {
            arcadeTexture.dispose();
            inventoryManagementTexture.dispose();
            worldMapTexture.dispose();
            planeTexture.dispose();
            
            arcadeMachineNodes.arcadeMachine.geometry.dispose();
            inventoryManagementNodes.inventoryManagement.geometry.dispose();
            worldMapNodes.World_Map.geometry.dispose();
            planeNodes.Plane.geometry.dispose();
            screenNodes.Television_Screen.geometry.dispose()
        };
    }, [arcadeTexture, inventoryManagementTexture, worldMapTexture, planeTexture, arcadeMachineNodes, inventoryManagementNodes, worldMapNodes, planeNodes]);


    return (
        <>
            
            <Text
                scale={projectsTextPositions.scale}
                position={projectsTextPositions.position}
                rotation={projectsTextPositions.rotation}
                font={InterSemiBoldFontPath}
                color={emphasisColour}
            >
                Projects
            </Text>
            <group ref ={meshReferences}>
                
                <Selection>
                    <EffectComposer enabled={[enabledWorldMap, enabledPlane, enabledArcadeMachine, enabledInventoryManagementReference]} autoClear={false}>
                        <Outline hiddenEdgeColor={hiddenEdgeColor} edgeStrength={edgeStrength} />
                    </EffectComposer>
                    <Select enabled={enabledWorldMap}>

                        <mesh
                            ref={worldMapReference}
                            geometry={worldMapNodes.World_Map.geometry}
                            scale={worldMapPositions.scale}
                            position={worldMapPositions.position}
                            rotation={worldMapPositions.rotation}
                            onClick={() => onHoverOverride(worldMapReference)}
                            onPointerOver={() => onHoverOverride(worldMapReference)}
                            onPointerLeave={() => handlePointerLeave()}
                        >
                            <meshBasicMaterial map={worldMapTexture} />
                        </mesh>
                    </Select>
                    <Select enabled={enabledPlane}>
                        <mesh
                            ref={planeReference}
                            geometry={planeNodes.Plane.geometry}
                            scale={planePositions.scale}
                            position={planePositions.position}
                            rotation={planePositions.rotation}
                            onClick={() => {onHoverOverride(planeReference)}}
                            onPointerOver={() => {onHoverOverride(planeReference)}}
                            onPointerLeave={() => handlePointerLeave()}
                        >
                            <meshBasicMaterial map={planeTexture} />
                        </mesh>
                    </Select>

                    <Select enabled={enabledArcadeMachine}>
                    <mesh
                        ref={arcadeMachineReference}
                        geometry={arcadeMachineNodes.arcadeMachine.geometry}
                        scale={arcadeMachinePositions.scale}
                        position={arcadeMachinePositions.position}
                        rotation={arcadeMachinePositions.rotation}
                        onClick={() => {onHoverOverride(arcadeMachineReference)}}
                        onPointerOver={() => {onHoverOverride(arcadeMachineReference)}}
                        onPointerLeave={() => handlePointerLeave()}
                    >
                        <meshBasicMaterial map={arcadeTexture} />
                    </mesh>
                    </Select>

                    <Select enabled={enabledInventoryManagementReference}>
                    <mesh
                        ref={inventoryManagementReference}
                        geometry={inventoryManagementNodes.inventoryManagement.geometry}
                        scale={inventoryManagementPositions.scale}
                        position={inventoryManagementPositions.position}
                        rotation={inventoryManagementPositions.rotation}
                        onClick={() => onHoverOverride(inventoryManagementReference)}
                        onPointerOver={() => onHoverOverride(inventoryManagementReference)}
                        onPointerLeave={() => handlePointerLeave()}
                    >
                        <meshBasicMaterial map={inventoryManagementTexture} />
                    </mesh>
                    </Select>
                </Selection>
            </group>
            
            <group ref = {detailsScreenReference} 
                position={[0,0,0]}
            >
                
                <mesh ref={televisionScreenReference}
                    geometry={screenNodes.Television_Screen.geometry}
                    rotation={screenPositions.rotation}
                    scale={screenPositions.scale}
                    position={screenPositions.position}
                >
                    <mesh rotation = {whiteScreenPositions.rotation} scale = {whiteScreenPositions.scale} position={whiteScreenPositions.position}>
                        <planeGeometry args={whiteScreenSize} />
                        <meshBasicMaterial
                            color={themeColour}
                        />
                    </mesh>
                </mesh>

                <group position = {screenPositions.position}>
                    {showInstructions && (
                        <Text
                            fontSize={titleFontSize}
                            position={titleTextPositions.position}
                            rotation={titleTextPositions.rotation}
                            anchorX="left"
                            anchorY="top"
                            color= {defaultColour}
                            maxWidth={textLineMaxWidth}
                            lineHeight={textLineHeight}
                            font={InterBoldFontPath}
                        >
                        {"< Hold down on the props to pin and view project details!"}
                        </Text> 

                    )}

                    {!showInstructions && (
                        <>
                            <Text
                                ref={titleContentReference}
                                fontSize={titleFontSize}
                                font={InterBoldFontPath}
                                position={titleTextPositions.position}
                                rotation={titleTextPositions.rotation}
                                anchorX="left"
                                anchorY="top"
                                color = {defaultColour}
                                onClick={() => handleHyperlinkClick()}
                                onPointerEnter={() => handlePointerEnter()}
                                onPointerLeave={() => handlePointerLeave()}
                            >
                                {projectTitle}
                            </Text> 



                            {descriptionText.map((item, index) => (

                            <Text
                                key={index}
                                fontSize={contentFontSize}
                                font={InterSemiBoldFontPath}

                                position={[
                                    descriptionStartingTextPositions.position[0], 
                                    descriptionStartingTextPositions.position[1] + (descriptionStartingTextParagraphDistance*(index+1)),
                                    descriptionStartingTextPositions.position[2]
                                ]}
                                rotation={descriptionStartingTextPositions.rotation}
                                anchorX="left"
                                anchorY="top"
                                color= {defaultColour} 
                                maxWidth={textLineMaxWidth}
                                lineHeight={textLineHeight}
                                
                                overflowWrap="break-word"
                            >
                                {item}
                            </Text>)
                            )}

                            <group>

                                <Text
                                    fontSize={semiTitleFontSize}
                                    font={InterBoldFontPath}
                                    position={skillsetTextPositions.position}
                                    rotation={skillsetTextPositions.rotation}
                                    anchorX="left"
                                    anchorY="top"
                                    color= {defaultColour} 
                                    maxWidth={textLineMaxWidth}
                                    lineHeight={textLineHeight}
                                    overflowWrap="break-word"
                                >
                                    Skillsets
                                </Text> 
                                {
                                    skillsetsUsed.map((item, index) => (
                                        <Text
                                            key={index}
                                            fontSize={contentFontSize}
                                            font={InterSemiBoldFontPath}
                                            position={[
                                                skillsetItemPositions.position[0],
                                                skillsetItemPositions.position[1] + (skillsetItemParagraphDistance*(index+1)),
                                                skillsetItemPositions.position[2]
                                            ]}
                                            rotation={skillsetItemPositions.rotation}
                                            anchorX="left"
                                            anchorY="top"
                                            color= {defaultColour} 
                                            maxWidth={textLineMaxWidth}
                                            lineHeight={textLineHeight}
                                            overflowWrap="break-word"
                                        >
                                            {item}
                                        </Text> 
                                    ))
                                }
                            </group>

                            <group>
                                <Text
                                    position={languagesTextPositions.position}
                                    rotation={languagesTextPositions.rotation}
                                    fontSize={semiTitleFontSize}
                                    font={InterBoldFontPath}
                                    anchorX="right"
                                    anchorY="top"
                                    color= {defaultColour} 
                                    maxWidth={textLineMaxWidth}
                                    lineHeight={textLineHeight}
                                    overflowWrap="break-word"
                                >
                                    Languages
                                </Text> 
                                { languagesUsed.map((item, index) => (
                                    <Text
                                        key={index}
                                        fontSize={contentFontSize}
                                        font={InterSemiBoldFontPath}
                                        position={[
                                            languagesItemPositions.position[0],
                                            languagesItemPositions.position[1] + (languagesItemParagraphDistance*(index+1)),
                                            languagesItemPositions.position[2]
                                        ]}
                                        rotation={languagesItemPositions.rotation}
                                        anchorX="right"
                                        anchorY="top"
                                        color= {defaultColour} 
                                        maxWidth={textLineMaxWidth}
                                        lineHeight={textLineHeight}
                                        overflowWrap="break-word"
                                    >
                                        {item}
                                    </Text> 
                                )) }
                            </group>
                            {(enabledLinks) && (
                                <group>
                                    <Text
                                        fontSize={linkFontSize}
                                        font={InterBoldFontPath}
                                        position={viewProjectLabelTextOnePositions.position}
                                        rotation={viewProjectLabelTextOnePositions.rotation}
                                        anchorX="right"
                                        anchorY="top"
                                        maxWidth={textLineMaxWidth}
                                        lineHeight={textLineHeight}
                                        overflowWrap="break-word"
                                        color = {isLinkHovered ? highlightColour : deemphasisColour}
                                        onClick={() => handleHyperlinkClick()}
                                        onPointerEnter={() => handlePointerEnter()}
                                        onPointerLeave={() => handlePointerLeave()}
                                    >
                                        {"View"}
                                    </Text>
                                    <Text
                                        fontSize={linkFontSize}
                                        font={InterBoldFontPath}
                                        position={viewProjectLabelTextTwoPositions.position}
                                        rotation={viewProjectLabelTextTwoPositions.rotation}
                                        anchorX="right"
                                        anchorY="top"
                                        maxWidth={textLineMaxWidth}
                                        lineHeight={textLineHeight}
                                        overflowWrap="break-word"
                                        color = {isLinkHovered ? highlightColour : deemphasisColour}
                                        onClick={() => handleHyperlinkClick()}
                                        onPointerEnter={() => handlePointerEnter()}
                                        onPointerLeave={() => handlePointerLeave()}
                                    >
                                        {"Project"}
                                    </Text>
                                    <Text
                                        ref={viewMoreReference}
                                        fontSize={linkFontSize}
                                        font={InterBoldFontPath}
                                        position={viewProjectArrowTextPositions.position}
                                        rotation={viewProjectArrowTextPositions.rotation}
                                        anchorX="left"
                                        anchorY="top"
                                        maxWidth={textLineMaxWidth}
                                        lineHeight={textLineHeight}
                                        overflowWrap="break-word"
                                        color = {isLinkHovered ? highlightColour : deemphasisColour}
                                        onClick={() => handleHyperlinkClick()}
                                        onPointerEnter={() => handlePointerEnter()}
                                        onPointerLeave={() => handlePointerLeave()}
                                    >
                                        {">"}
                                    </Text>
                                </group>)}
                        </>
                    )}

                </group>
                
            </group>
            
        </>
    );
}