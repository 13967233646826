import { useEffect, useState } from 'react';

export function useTextScaleAndCenter(viewport, camera, textXOffset, textYOffset) {
    const [textScale, setTextScale] = useState(1);
    const [centerX, setCenterX] = useState(0);
    const [centerY, setCenterY] = useState(0);

    useEffect(() => {
        const updateTextScale = () => {
            const { width, height } = viewport;
            const aspectRatio = width / height;
            const verticalFov = (camera.fov * Math.PI) / 180;
            const viewportHeight = 2 * Math.tan(verticalFov / 2);
            const newWidth = (viewportHeight * aspectRatio) / 5;

            setTextScale(newWidth);
            setCenterX(newWidth + textXOffset);
            setCenterY(viewportHeight + textYOffset);
        };

        updateTextScale();
        window.addEventListener('resize', updateTextScale);

        return () => window.removeEventListener('resize', updateTextScale);
    }, [viewport, camera, textXOffset, textYOffset]);

    return { textScale, centerX, centerY };
}