import React, { useState } from 'react';
import { Center, Float, Text3D } from "@react-three/drei";

const MultilineText3D = ({
        lines,
        position,
        rotation,
        scale,
        font,
        lineHeight,
        textThickness,
        setMousePointerIcon,
        settings
    }) => {
        
        const { defaultColour, highlightColour} = settings.colours.linkColours
        const [hoveredIndex, setHoveredIndex] = useState(null);
        const [hoveredLink, setHoveredLink] = useState(null);

        const handleClick = (link) => {
            if (link) {
                window.open(link, '_blank');
            }
        };
        const highlightClickable = (index) => {
            setHoveredIndex(index);
            setHoveredLink(index);
            setMousePointerIcon(true);
        }
        const unhighlightClickable = () => {
            setHoveredIndex(null);
            setHoveredLink(null);
            setMousePointerIcon(false);
        }

        const handlePointerEnter = (index) => {
            if (lines[index].link) {
                highlightClickable(index)
            }
        };

        const handlePointerLeave = () => {
            unhighlightClickable()
        };


        return (
            <Float
                speed={2.5}
                rotationIntensity={0.1}
                floatIntensity={0.3}
                floatingRange={[-0.01, 0.01]}
            >
                <Center position={position} rotation={rotation} scale={scale}>
                    {lines.map((line, index) => (
                        <group 
                            key={index} 
                            onClick={() => handleClick(line.link)}
                            position={[10, -index * lineHeight + line.verticalOffset, 0]}
                        >
                            <mesh>
                                <Text3D
                                    font={font}
                                    scale={[line.scale, line.scale, 1]}
                                    height={textThickness}
                                    onPointerEnter={() => handlePointerEnter(index)}
                                    onPointerLeave={() => {handlePointerLeave()}}
                                >
                                    <meshBasicMaterial
                                        color={hoveredLink === index ? highlightColour : defaultColour}
                                    />
                                    {line.text}
                                </Text3D>
                            </mesh>

                            {line.link && (
                                <>
                                    <group
                                    
                                        onClick={() => handleClick(line.link)}
                                        position={[30, 0.5, 0]}
                                        onPointerEnter={() => handlePointerEnter(index)}
                                        onPointerLeave={() => {handlePointerLeave()}}
                                    >
                                        
                                        <mesh
                                            position={[-15, 0, 0.001]}
                                            onPointerEnter={() => handlePointerEnter(index)}
                                            onPointerLeave={() => handlePointerLeave()}
                                            onClick={() => handleClick(line.link)}
                                        >
                                            {/* Transparent click box that spans the entire screen width*/}
                                            <boxGeometry args={[100, lineHeight*1.5, 0.01]} />
                                            <meshStandardMaterial transparent opacity={0} />
                                        </mesh>
                                        <mesh
                                        >
                                            <circleGeometry args={[textThickness * 100, 3]} />
                                            <meshBasicMaterial
                                                color={hoveredIndex === index ? highlightColour : defaultColour}
                                                opacity={hoveredIndex === index ? 1 : 0.5}
                                            />
                                        </mesh>
                                        <mesh
                                        >
                                            <ringGeometry args={[textThickness * 110, textThickness * 120, 3]} />
                                            <meshBasicMaterial
                                                color={hoveredIndex === index ? highlightColour : defaultColour}
                                                opacity={hoveredIndex === index ? 1 : 0.5}
                                            />
                                        </mesh>

                                    </group>
                                    
                                </>
                            )}
                        </group>
                    ))}
                </Center>
            </Float>
        );
};

export default MultilineText3D;
