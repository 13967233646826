import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Html, useGLTF, useTexture } from "@react-three/drei";
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import * as THREE from 'three'
import { precomputedExperiencesPositions } from '../precomputedPositions';
import { htmlExperienceIndexMap } from './data/experienceInformation';

const {car, tabletopAndObjects} = precomputedExperiencesPositions
const {positions: carPositions, scale: carScale, rotation: carRotations } = car
const {position: timelinePosition, scale: timelineScale, rotation: timelineRotation } = tabletopAndObjects


export default function ExperiencesSection({ displayTimeline, settings}) {
    
    const carAnimationSettings = settings.animations.experiencesSection.car.toDestination

    const {car: carModelPath, carTexture: carTexturePath, tabletop: tabletopModelPath, tabletopTexture: tabletopTexturePath} = settings.publicFilePaths.models.experiencesSection


    const [currentLocation, setCurrentLocation] = useState('central')

    const carReference = useRef(null)
    const experiencesReference = useRef(null)
    const informationTextReference = useRef(null)

    gsap.registerPlugin(useGSAP)

    const { contextSafe: carContext } = useGSAP({ scope: carReference });

    const runMoveCarAnimation = carContext((fromLocation, toLocation, callback) => {
        const yRotationRequired = computeYRotation(currentLocation, toLocation)
        const timeline = gsap.timeline({
            onComplete: () => {
                callback(toLocation)
            }
        });
        timeline.to(carReference.current.rotation, {
            y: yRotationRequired,
            duration: carAnimationSettings.rotation.duration,
            ease: carAnimationSettings.rotation.easeType,
        }, carAnimationSettings.rotation.startOn);
        timeline.to(carReference.current.position, {
            x: carPositions[toLocation][2],
            z: carPositions[toLocation][0],
            duration: carAnimationSettings.position.duration,
            ease: carAnimationSettings.position.easeType,
        }, carAnimationSettings.position.startOn);

    });



    const computeYRotation = (fromLocation, toLocation) => {
        const fromVector = new THREE.Vector3(carPositions[fromLocation][2], 0, carPositions[fromLocation][0]);
        const toVector = new THREE.Vector3(carPositions[toLocation][2], 0, carPositions[toLocation][0]);
    
    
        const deltaX = toVector.x - fromVector.x;
        const deltaZ = toVector.z - fromVector.z;
    
        const angleRadians = Math.atan2(deltaZ, deltaX);
    
        return -angleRadians; // - to counterclockwise the angle of rotation
    }

    const onClickAnimation = (toLocation) => {
        runMoveCarAnimation(currentLocation, toLocation, () => {setCurrentLocation(toLocation)})
    }

    useEffect(() => {
        carReference.current.position.setZ(carPositions[currentLocation][0])
        carReference.current.position.setY(carPositions[currentLocation][1])
        carReference.current.position.setX(carPositions[currentLocation][2])
    }, [currentLocation])

    useEffect(() => {
        const experiencesReference = document.getElementById('experiences');
        const informationTextReference = document.getElementsByClassName('informationPanel')[0];
        const experienceWrapperReference = document.getElementsByClassName('experienceWrapper')[0];

        if (experienceWrapperReference) {
            experienceWrapperReference.style.opacity = displayTimeline ? 1 : 0;
            experienceWrapperReference.style.visibility = displayTimeline ? 'visible' : 'hidden'
        } 
        
        if (experiencesReference && informationTextReference) {
            informationTextReference.style.opacity = displayTimeline ? 1 : 0;
            experiencesReference.style.opacity = displayTimeline ? 1 : 0;
            informationTextReference.style.visibility = displayTimeline ? 'visible' : 'hidden';
            experiencesReference.style.visibility = displayTimeline ? 'visible' : 'hidden';

            const yearElements = experiencesReference.getElementsByClassName('year');
            
            Object.keys(htmlExperienceIndexMap).forEach(eventKey => {


                const { yearIndex, eventIndex, location, content } = htmlExperienceIndexMap[eventKey];
                const {title, duration, description} = content




                const eventEntry = yearElements[yearIndex].children[3].children[eventIndex];
                eventEntry.onclick = async () => {
                    onClickAnimation(location)
                    informationTextReference.children[0].textContent = title
                    informationTextReference.children[1].textContent = duration
                    description.forEach((paragraphText, index) => {
                        informationTextReference.children[2].children[index].textContent = paragraphText
                    })
                };
            });

            return () => {
                Object.keys(htmlExperienceIndexMap).forEach(eventKey => {
                    const { yearIndex, eventIndex } = htmlExperienceIndexMap[eventKey];
                    const eventEntry = yearElements[yearIndex].children[3].children[eventIndex];
                    eventEntry.onclick = null;
                });
            };
        }
    }, [displayTimeline]);

    const {nodes: timelineNodes} = useGLTF(tabletopModelPath)
    const timelineTexture = useTexture(tabletopTexturePath)
    timelineTexture.flipY = false;

    const { nodes: carNodes } = useGLTF(carModelPath);
    const carTexture = useTexture(carTexturePath);

    return (
        <>
            <mesh geometry={timelineNodes.Timeline.geometry}
                position = {timelinePosition}
                scale={timelineScale}
                rotation={timelineRotation}
            >
                <meshBasicMaterial map={timelineTexture} />

            </mesh>           
            
            <mesh
                geometry={carNodes.CarModel.geometry}
                scale={carScale}
                rotation={carRotations}
                ref={carReference}
            >
                <meshBasicMaterial map={carTexture} />

            </mesh>
            <Html portal={experiencesReference.current} />
            <Html portal={informationTextReference.current} />
        </>
    );
}
