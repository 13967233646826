import React, { useEffect, useRef } from 'react';
import { Html } from "@react-three/drei";

export default function TopNavigationBar({ 
        runNavigationAnimation,
        updateNavigationalUI,
        displayTopNavigationBar,
        currentSectionIndex,
        setCurrentSectionIndex,
        sections,
        resetOrbitControls,
        setHistoryStack
    }) {
        const portalReference = useRef(null);

        const handleItemClick = (index) => {
            const desiredSection = sections[index];

            setCurrentSectionIndex(index);
            
            setHistoryStack((previousStack) => [...previousStack, desiredSection]);
            
            runNavigationAnimation(desiredSection, () => {updateNavigationalUI(desiredSection)});
            resetOrbitControls();
        };


        useEffect(() => {
            const portalElement = document.getElementById('navigationBar');

            if (!portalElement) return;

            const listItems = portalElement.querySelectorAll('li');

            listItems.forEach((item, index) => {
                const clickHandler = () => handleItemClick(index);
                item.addEventListener('click', clickHandler);
                return () => item.removeEventListener('click', clickHandler);
            });
        }, [sections]);


        useEffect(() => {
            const portalElement = document.getElementById('navigationBar');

            if (!portalElement) return;
        
            const listItems = portalElement.querySelectorAll('li');
            listItems.forEach((li, index) => {
                li.classList.toggle('active', index === currentSectionIndex);
            });

        }, [currentSectionIndex]);

            
        useEffect(() => {
            const portalElement = document.getElementById('navigationBar');

            if (portalElement) {
                portalElement.style.opacity = displayTopNavigationBar ? 1 : 0;
                portalElement.style.visibility = displayTopNavigationBar ? 'visible' : 'hidden';
            }

        }, [displayTopNavigationBar]);
    
        return (
            <>
                <Html portal={portalReference.current} />
            </>
        );
}
