const yearIndexMap = { /* Synchronise with HTML */
    '2021' : 0,
    '2023' : 1,
    '2024' : 2
}

export const htmlExperienceIndexMap = { /* Synchronise with descriptions.js*/
    'SUTD': {
        yearIndex: yearIndexMap['2021'],
        eventIndex: 0,
        location: 'east',
        contentName: 'SUTD',
        content: {
            title: "Singapore University of Technology and Design (SUTD)",
            duration: "January 2021 to April 2025",
            description: [
                "Enrolled in Computer Science.",
                "Currently in my last year of studies."
            ]
        }
    },
    'Castlery': {
        yearIndex: yearIndexMap['2021'],
        eventIndex: 1,
        location: 'south',
        contentName: 'Castlery',
        content: {
            title: "Castlery",
            duration: "February 2021 to December 2021",
            description: [
                "I spent quite some time here as a part-time job initially doing logistics and admin work.",
                "After a few months, I was given the opportunity to assist in digitalisation and automation of data fetching from various sources beyond my part time job scope.",
                ""
            ]
        }
    },
    'Simmons': {
        yearIndex: yearIndexMap['2023'],
        eventIndex: 0,
        location: 'east',
        contentName: 'Simmons',
        content: {
            title: "Simmons",
            duration: "June 2023",
            description: [
                "Did freelance work which involved data processing using VBA.",
                "",
                ""
            ]
        }
    },
    'A*STAR': {
        yearIndex: yearIndexMap['2023'],
        eventIndex: 1,
        location: 'west',
        contentName: 'ASTAR',
        content: {
            title: "ASTAR",
            duration: "August 2023 to December 2023",
            description: [
                "Worked on developing features for quantitative scoring and assessment of sustainability for companies.",
                "Focused on full-stack web application development, developing new features and functionality.",
                ""
            ]
        }
    },

    'PicoCTF': {
        yearIndex: yearIndexMap['2024'],
        eventIndex: 0,
        location: 'central',
        contentName: 'PicoCTF',
        content: {
            title: "PicoCTF 2024",
            duration: "March",
            description: [
                "I spent a few months prior on HackTheBox and PicoCTF gym to learn more about cybersecurity before trying out PicoCTF 2024.",
                "",
                ""
            ]
        }
    },

    'ExchangeStudies': {
        yearIndex: yearIndexMap['2024'],
        eventIndex: 1,
        location: 'east',
        contentName: 'ExchangeStudies',
        content: {
            title: "Exchange Studies in Iceland",
            duration: "January 2024 to April 2024",
            description: [
                "I went on overseas exchange studies in Iceland in Reykjavik University.",
                "It was a great short experience of an absolutely different culture, weather and place.",
                ""
            ]
        }
    },

    'PortfolioWebsite': {
        yearIndex: yearIndexMap['2024'],
        eventIndex: 2,
        location: 'central',
        contentName: 'Portfolio',
        content: {
            title: "This Website",
            duration: "April 2024 to June 2024",
            description: [
                "I dedicated a lot of my time in building this website which integrates WebGL and the 2D web experience.",
                "The goal of this website is to showcase relevant works and experiences of past 5 years.",
                ""
            ]
        }
    }
}