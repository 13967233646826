
export async function fetchSettings(filePath) {
    try {
        const response = await fetch(filePath);
        if (!response.ok) {
            throw new Error(`Failed to fetch file ${filePath}`);
        }
        return await response.json();
    } catch (error) {
        console.error(`Error fetching settings file ${filePath}:`, error);
        throw error;
    }
}
