
import { Suspense, useEffect, useLayoutEffect, useRef, useState } from "react";
import { OrbitControls, PerspectiveCamera, Sparkles, Stars, useGLTF, useTexture } from "@react-three/drei";
import { useLocation } from "react-router-dom";
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import CanvasSection from './canvasSection/CanvasSection.jsx';
import ProjectsSection from './projectsSection/ProjectsSection.jsx';
import ExperiencesSection from "./experienceSection/ExperiencesSection.jsx";
import ContactSection from "./contactSection/ContactSection.jsx";
import Navigation from './navigation/Navigation.jsx'
import { precomputedRoomPositions, precomputedCameraPositions } from "./precomputedPositions.js";
import { useThree } from "@react-three/fiber";
import { fetchSettings } from "./loadPublicFile.js";


const canvasPosition = precomputedRoomPositions.canvasSection.position
const canvasRotation = precomputedRoomPositions.canvasSection.rotation
const projectsAndExperiencesPosition = precomputedRoomPositions.projectsAndExperiencesSection.position
const projectsAndExperiencesRotation = precomputedRoomPositions.projectsAndExperiencesSection.rotation

const HTMLSectionMap = {
    "/": 0,
    "/projects":1,
    "/experiences":2,
    "/contact":3
}

export default function App() {
    const {nodes: canvasNodes} = useGLTF('./models/Room/CanvasSection.glb')
    const {nodes: projectsAndExperiencesNodes} = useGLTF('./models/Room/ProjectsAndExperiencesSection.glb')

    const canvasTexture = useTexture('./models/Room/CanvasSection.jpg')
    const projectsAndExperiencesTexture = useTexture('./models/Room/ProjectsAndExperiencesSection.jpg')

    canvasTexture.flipY = false;
    projectsAndExperiencesTexture.flipY = false;

    gsap.registerPlugin(useGSAP)

    const location = useLocation();

    const perspectiveCameraReference = useRef()
    const orbitControlsReference = useRef()
    const [historyStack, setHistoryStack] = useState([location.pathname]);

    const { contextSafe: perspectiveCameraContext } = useGSAP({ scope: perspectiveCameraReference });
    const { contextSafe: orbitControlsContext } = useGSAP({ scope: orbitControlsReference });

    const [displayTimeline, setDisplayTimeline] = useState(location.pathname === '/experiences' ? false : true)
    const [displayContactInformation, setDisplayContactInformation] = useState(location.pathname === '/contact' ? false : true)
    
    const [cameraPosition, setCameraPosition] = useState(precomputedCameraPositions[location.pathname].position);
    const [cameraRotation, setCameraRotation] = useState(precomputedCameraPositions[location.pathname].rotation);
    const [screenOn, setScreenOn] = useState(false)
    const [settings, setSettings] = useState({})
    const [settingsLoaded, setSettingsLoaded] = useState(false)
    const [navigationAnimationSettings, setNavigationAnimationSettings] = useState({})
    const [sections, setSections] = useState({})
    const [navigationalMap, setNavigationalMap] = useState({})
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [sectionsLoaded, setSectionsLoaded] = useState(false)


    const resetOrbitControls = orbitControlsContext(() => {
        orbitControlsReference.current.reset()
    })

    useEffect(() => {
        fetchSettings('settings.json').then((desiredSettings) => {
            setSettings(desiredSettings)
            setNavigationAnimationSettings(desiredSettings.animations.navigation.camera)
            setSettingsLoaded(true)
        })
        
        setNavigationalMap(HTMLSectionMap)
        setSections(Object.keys(HTMLSectionMap));
        setCurrentSectionIndex(HTMLSectionMap[location.pathname])
        setSectionsLoaded(true)

    }, [])

    const { camera } = useThree();
    
    useEffect(() => {
        const handleResize = () => {
            const aspect = window.innerWidth / window.innerHeight;
            const newFOV = calculateFOV();

            camera.fov = newFOV;
            camera.aspect = aspect;
            camera.updateProjectionMatrix();
        };

        if (camera) {
            window.addEventListener('resize', handleResize);
            handleResize();
        }

        return () => window.removeEventListener('resize', handleResize);
    }, [camera, cameraPosition, cameraRotation]);

    
    useEffect(() => {
        (location.pathname === '/projects') ? setScreenOn(true) : setScreenOn(false)
    },[location.pathname])


    const runNavigationAnimation = perspectiveCameraContext((desiredSection, onAnimationCompleteCallback = () => {}) => {
        resetOrbitControls()
        const timeline = gsap.timeline({
            onComplete: () => {
                onAnimationCompleteCallback();
            }
        });

        timeline.to(perspectiveCameraReference.current.position, {
            x: precomputedCameraPositions.intermediary.position[0],
            y: precomputedCameraPositions.intermediary.position[1],
            z: precomputedCameraPositions.intermediary.position[2],
            duration: navigationAnimationSettings?.toIntermediary?.position?.duration || 1.5,
            ease: navigationAnimationSettings?.toIntermediary?.position?.easeType || "power1.inOut",
        }, navigationAnimationSettings?.toIntermediary?.position?.startOn || 0);

        timeline.to(perspectiveCameraReference.current.rotation, {
            x: precomputedCameraPositions.intermediary.rotation[0],
            y: precomputedCameraPositions.intermediary.rotation[1],
            z: precomputedCameraPositions.intermediary.rotation[2],
            duration: navigationAnimationSettings?.toIntermediary?.rotation?.duration || 1.5,
            ease: navigationAnimationSettings?.toIntermediary?.rotation?.easeType || "power1.inOut",
        }, navigationAnimationSettings?.toIntermediary?.rotation?.startOn || 0);

        timeline.to(perspectiveCameraReference.current.position, {
            x: precomputedCameraPositions[desiredSection].position[0],
            y: precomputedCameraPositions[desiredSection].position[1],
            z: precomputedCameraPositions[desiredSection].position[2],
            duration: navigationAnimationSettings?.toDestination?.position?.duration || 2,
            ease: navigationAnimationSettings?.toDestination?.position?.easeType || "power2.inOut",
        }, navigationAnimationSettings?.toDestination?.position?.startOn || 1.5);

        timeline.to(perspectiveCameraReference.current.rotation, {
            x: precomputedCameraPositions[desiredSection].rotation[0],
            y: precomputedCameraPositions[desiredSection].rotation[1],
            z: precomputedCameraPositions[desiredSection].rotation[2],
            duration: navigationAnimationSettings?.toDestination?.rotation?.duration || 2,
            ease: navigationAnimationSettings?.toDestination?.rotation?.easeType || "power2.inOut"
        },  navigationAnimationSettings?.toDestination?.rotation?.startOn || 1.5);

    });

    const runNavigationAnimationUpdateHistory = (desiredSection) => {
        setCurrentSectionIndex(navigationalMap[desiredSection])
        runNavigationAnimation(desiredSection, () => {
            setHistoryStack(previousStack => [...previousStack, desiredSection]);
        });
    };
    
    const calculateFOV = () => {
        const width = window.outerWidth;
    
        const minFOV = 75;
        const maxFOV = 120;
        const minWidth = 400;
        const maxWidth = 2000;
    
        if (width <= minWidth) {
            return maxFOV;
        } else if (width >= maxWidth) {
            return minFOV;
        }
        return Math.round(maxFOV - ((width - minWidth) / (maxWidth - minWidth)) * (maxFOV - minFOV))
    };
    


    const [mousePointerIcon, setMousePointerIcon] = useState(false)

    useEffect(() => {
        mousePointerIcon ? document.body.style.cursor = 'pointer': document.body.style.cursor = 'default' 

    }, [mousePointerIcon])
    
    useLayoutEffect(() => {
        if (orbitControlsReference.current) {
            orbitControlsReference.current.saveState()
        }
    },[orbitControlsReference])


    return (
        <>
            {(sectionsLoaded) && <Navigation 
                setDisplayTimeline={setDisplayTimeline}
                setDisplayContactInformation={setDisplayContactInformation}
                setCameraPosition = {setCameraPosition}
                setCameraRotation = {setCameraRotation}
                historyStack = {historyStack}
                setHistoryStack = {setHistoryStack}
                runNavigationAnimationUpdateHistory = {runNavigationAnimationUpdateHistory}
                runNavigationAnimation = {runNavigationAnimation}
                resetOrbitControls = {resetOrbitControls}
                currentSectionIndex={currentSectionIndex}
                setCurrentSectionIndex={setCurrentSectionIndex}
                navigationalMap={navigationalMap}
                sections={sections}
            >
                
            </Navigation>}
            <group>
                <color attach="background" args={[settings.backgroundColour || 'azure']} />
                <ambientLight />
                
            </group>
            
            <PerspectiveCamera
                ref={perspectiveCameraReference}
                position={cameraPosition}
                rotation={cameraRotation}
            >
            <OrbitControls 
                ref={orbitControlsReference}
                enableDamping={true}
                dampingFactor={0.2} 
                minPolarAngle={1.55} 
                maxPolarAngle={1.65}
                minAzimuthAngle={-0.1}
                maxAzimuthAngle={0.1}
                enableRotate={true}
                rotateSpeed={0.1}
                enablePan={true} 
                panSpeed={0.01}
                enableZoom={true}
                zoomSpeed={0.5}
                minDistance={4}
                maxDistance={5}
            />
            <Suspense>
                <mesh 
                    geometry={canvasNodes.CanvasSection.geometry}
                    rotation={canvasRotation}
                    position={canvasPosition}
                >
                    <meshBasicMaterial map={canvasTexture} />
                </mesh>
            </Suspense>
            <Suspense>
                    <mesh 
                        geometry={projectsAndExperiencesNodes.ProjectsAndExperiencesSection.geometry}
                        rotation={projectsAndExperiencesRotation}
                        position={projectsAndExperiencesPosition}
                    >
                        <meshBasicMaterial map={projectsAndExperiencesTexture} />
                    </mesh>
                </Suspense>

            {(settingsLoaded) && (

                <group>
                    
                    <CanvasSection 
                        runNavigationAnimationUpdateHistory = {runNavigationAnimationUpdateHistory}
                        setMousePointerIcon = {setMousePointerIcon}
                        settings = {settings}
                    />
                    
                    <Suspense>
                        <ProjectsSection
                            setMousePointerIcon = {setMousePointerIcon}
                            screenOn = {screenOn} 
                            settings = {settings}
                        />
                    </Suspense>
                    <Suspense>
                        <ExperiencesSection 
                            displayTimeline={displayTimeline} 
                            settings = {settings}
                        />
                    </Suspense>
                    <Suspense>
                        <ContactSection 
                            displayContactInformation={displayContactInformation}
                            setMousePointerIcon={setMousePointerIcon}
                            settings = {settings}
                        />
                    </Suspense>

                </group>
            )}
            
            </PerspectiveCamera>
            
        </>
    );
}