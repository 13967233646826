import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopNavigationBar from "./TopNavigationBar";

export default function Navigation({
        sections, 
        currentSectionIndex, 
        setCurrentSectionIndex, 
        historyStack, 
        setHistoryStack, 
        setDisplayTimeline, 
        runNavigationAnimationUpdateHistory,
        runNavigationAnimation,
        resetOrbitControls,
        setDisplayContactInformation
    }) {


    const location = useLocation();
    const navigate = useNavigate();

    const [displayTopNavigationBar, setDisplayTopNavigationBar] = useState(location.pathname !== "/");

    const updateNavigationalUI = (currentSection) => {
        navigate(currentSection);
        setDisplayTopNavigationBar(currentSection !== "/");
        setDisplayTimeline(currentSection === "/experiences");
        setDisplayContactInformation(currentSection === "/contact")
    };

    useEffect(() => {

        const historyLength = historyStack.length;
        const previousSection = historyStack.at(-2);



        const handleBack = () => {

            if (historyLength > 1) { // If history length <= 1, do nothing
                runNavigationAnimationUpdateHistory(previousSection)
                updateNavigationalUI(previousSection)
            }
        };


    
        const currentSection = historyStack.at(-1);

        updateNavigationalUI(currentSection);


        window.addEventListener("popstate", handleBack);

        return () => {
            window.removeEventListener("popstate", handleBack);
        };
    }, [historyStack]);

    return (
        <>  
            <TopNavigationBar
                runNavigationAnimation = {runNavigationAnimation}
                updateNavigationalUI = {updateNavigationalUI}
                displayTopNavigationBar = {displayTopNavigationBar}
                setHistoryStack={setHistoryStack}
                currentSectionIndex={currentSectionIndex}
                setCurrentSectionIndex={setCurrentSectionIndex}
                sections={sections}
                resetOrbitControls={resetOrbitControls}
            />
        </>
    );
}
