
export const projectsDetails = {
    DNSAIProject: {
        title:"DNS Monitoring with AI",
        link:"https://github.com/SeanLimHH/DNS-Monitoring-With-Machine-Learning",
        description: [
            "This attempts to recreate a small part of existing Intrusion Detection Systems (IDS) like Suricata and Snort.",
            "The project uses AI algorithms to analyse the DNS traffic and resolver cache, flagging out suspicious packets, domain names."],
        skillsets: ["Artificial Intelligence", "Probability and Statistics", "DNS"],
        languages:["Python"]
    },
    TrajectoryProject: {
        title:"Plane Trajectory Prediction",
        link:"https://github.com/SeanLimHH/AI-Kalman-Filter-Project.git",
        description:[
            "The project involves using Kalman Filters to make better estimations of a plane's position and velocity using noisy measurements.",
            "Fundamentally, this involves using Bayesian Inference to update the probability distributions of the next state."],
        skillsets: ["Artificial Intelligence", "Linear Algebra", "Probability and Statistics"],
        languages:["Python"]
    },
    ArcadeMachineProject: {
        title:"Arcade Machine",
        link:"https://github.com/SeanLimHH/Arcade-Game.git",
        description:[
            "In this school project, we used Lucid HDL to program an FPGA and build an arcade machine from scratch.",
            "The project involved designing the ALU, computational systems, and user interface (buttons and screen)."],
        skillsets: ["FPGA", "Microcontroller Programming", "Digital Circuit Design", "Embedded Systems"],
        languages:["Lucid HDL"]
    },
    InventoryManagementProject: {
        title:"Inventory Management",
        link:"",
        description:[
            "This was a school project that involved building a full-stack web application for a company.",
            "One of the key features is the extraction of characters from transactions and receipts into a database system."],
        skillsets: [
            "MongoDB",
            "Node.js",
            "React",
            "Chai"
        ],
        languages:[
            "Python",
            "JavaScript"
        ]
    }

}