import React from 'react';
import { Sky } from "@react-three/drei";
import MultilineText3D from './MultilineText3D.jsx';
import { precomputedContactPositions } from '../precomputedPositions.js';
import { useTextScaleAndCenter } from './useTextScaleAndCenter';
import { useThree } from '@react-three/fiber';


const multilineTextRotations = precomputedContactPositions.multilineText.rotation;



export default function ContactSection({ displayContactInformation, setMousePointerIcon, settings }) {
    const InterSemiBoldFontPath3D = settings.publicFilePaths.fonts.InterSemiBoldFontPath3D
    const { contactSection } = settings;
    const { lineHeight, textThickness, sky, textXOffset, textYOffset } = contactSection;
    const { distance: skyDistance, sunPosition: skySunPosition } = sky;

    const { viewport, camera } = useThree();
    const { textScale, centerX, centerY } = useTextScaleAndCenter(viewport, camera, textXOffset, textYOffset);

    const informationTexts = [
        { text: "Sean Lim Hsien Hong", scale: 2.25, verticalOffset: 2 },
        { text: "Singapore University of Technology and Design", scale: 1, verticalOffset: 0 },
        { text: "Computer Science", scale: 0.7, verticalOffset: 0 },
        { text: "Year 3", scale: 0.7, verticalOffset: 0 },
        { text: contactSection["LinkedIn"].label, scale: 1, verticalOffset: -1.75, link: contactSection["LinkedIn"].link },
        { text: contactSection["GitHub"].label, scale: 1, verticalOffset: -3.5, link: contactSection["GitHub"].link },
        { text: contactSection["email"].label, scale: 1, verticalOffset: -5.25, link: contactSection["email"].link },
    ];

    return (
        <>
            <Sky distance={skyDistance} sunPosition={skySunPosition} />
            {displayContactInformation && (
                <MultilineText3D
                    lines={informationTexts.map(line => ({
                        text: line.text,
                        scale: line.scale,
                        verticalOffset: line.verticalOffset,
                        link: line.link
                    }))}
                    position={[centerX / 4, centerY / 4, 22]}
                    rotation={multilineTextRotations}
                    scale={[textScale, textScale, 1]}
                    lineHeight={lineHeight}
                    font={InterSemiBoldFontPath3D}
                    textThickness={textThickness}
                    setMousePointerIcon={setMousePointerIcon}
                    settings={settings}
                />
            )}
        </>
    );
}
