import { useEffect, useRef, useState } from 'react';
import { Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { precomputedCanvasPositions } from '../precomputedPositions.js';

const {
    SeanText: SeanTextPositions,
    dateText: dateTextPositions,
    portfolioText: portfolioTextPositions,
    _Text: _TextPositions,
    projectsText: projectsTextPositions,
    experiencesText: experiencesTextPositions,
    contactText: contactTextPositions
} = precomputedCanvasPositions;

const CanvasSection = ({
    runNavigationAnimationUpdateHistory,
    setMousePointerIcon,
    settings,
}) => {
    const {emphasisColour, deemphasisColour} = settings.colours
    const InterSemiBoldFontPath = settings.publicFilePaths.fonts.InterSemiBoldFontPath;
    const InterBoldFontPath = settings.publicFilePaths.fonts.InterBoldFontPath;

    const SeanText = useRef();
    const dateText = useRef();
    const portfolioText = useRef();
    const projectsText = useRef();
    const experiencesText = useRef();
    const contactText = useRef();
    const underscoreText = useRef();

    let textReferences = [SeanText, dateText, portfolioText, underscoreText, projectsText, experiencesText, contactText];

    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear().toString().slice(-2);

        setCurrentDate(`${day} . ${month} . ${year}`);
    }, []);

    useFrame((state) => {
        if (state.clock.getElapsedTime() % 1 < 0.5) {
            underscoreText.current.visible = true;
        } else {
            underscoreText.current.visible = false;
        }
        underscoreText.current.sync();
    });

    const deEmphasiseRestAnimation = (emphasisedElement) => {
        textReferences.forEach((element) => {
            if (element.current) {
                element.current.color = deemphasisColour;
            }
        });
        underscoreText.current.position.z -= 1;
        if (emphasisedElement?.current) {
            emphasisedElement.current.color = emphasisColour;
        }
        troikaTextSynchronise();
    };

    const reEmphasiseAllAnimation = () => {
        textReferences.forEach((element) => {
            if (element.current) {
                element.current.color = emphasisColour;
            }
        });
        underscoreText.current.position.z += 1;
        troikaTextSynchronise();
    };

    const troikaTextSynchronise = () => {
        if (textReferences.every((ref) => ref.current)) {
            textReferences.forEach((element) => {
                element.current.sync();
            });
        }
    };

    const handleNavigationClick = (section) => {
        runNavigationAnimationUpdateHistory(section);
    };

    return (
        <>
                
                <Text
                    scale={SeanTextPositions.scale}
                    ref={SeanText}
                    position={SeanTextPositions.position}
                    rotation={SeanTextPositions.rotation}
                    anchorX='left'
                    anchorY='left'
                    font={InterSemiBoldFontPath}
                    color={emphasisColour}
                >
                    Sean's
                </Text>

                <Text
                    scale={dateTextPositions.scale}
                    ref={dateText}
                    position={dateTextPositions.position}
                    rotation={dateTextPositions.rotation}
                    anchorX='right'
                    anchorY='left'
                    font={InterSemiBoldFontPath}
                    color={emphasisColour}
                >
                    {currentDate}
                    
                </Text>

                <Text
                    scale={portfolioTextPositions.scale}
                    ref={portfolioText}
                    position={portfolioTextPositions.position}
                    rotation={portfolioTextPositions.rotation}
                    anchorX='left'
                    anchorY='left'
                    font={InterBoldFontPath}
                    color={emphasisColour}
                >
                    portfolio
                </Text>
                <Text
                    scale={_TextPositions.scale}
                    ref={underscoreText}
                    position={_TextPositions.position}
                    rotation={_TextPositions.rotation}
                    anchorX='left'
                    anchorY='left'
                    font={InterSemiBoldFontPath}
                    color={emphasisColour}
                >
                    _
                </Text>
                <Text
                    ref={projectsText}
                    anchorX='left'
                    anchorY='left'
                    font={InterSemiBoldFontPath}
                    color={emphasisColour}
                    position={projectsTextPositions.position}
                    rotation={projectsTextPositions.rotation}
                    scale={projectsTextPositions.scale}
                    onPointerOver={() => {
                        setMousePointerIcon(true);
                        deEmphasiseRestAnimation(projectsText);
                    }}
                    onPointerLeave={() => {
                        setMousePointerIcon(false);
                        reEmphasiseAllAnimation();
                    }}
                    onClick={() => handleNavigationClick('/projects')}
                >
                    / projects
                </Text>

                <Text
                    ref={experiencesText}
                    anchorX='left'
                    anchorY='left'
                    font={InterSemiBoldFontPath}
                    color={emphasisColour}
                    position={experiencesTextPositions.position}
                    rotation={experiencesTextPositions.rotation}
                    scale={experiencesTextPositions.scale}
                    onPointerOver={() => {
                        setMousePointerIcon(true);
                        deEmphasiseRestAnimation(experiencesText);
                    }}
                    onPointerLeave={() => {
                        setMousePointerIcon(false);
                        reEmphasiseAllAnimation();
                    }}
                    onClick={() => handleNavigationClick('/experiences')}
                >
                    / experiences
                </Text>
                <Text
                    ref={contactText}
                    anchorX='left'
                    anchorY='left'
                    font={InterSemiBoldFontPath}
                    color={emphasisColour}
                    position={contactTextPositions.position}
                    rotation={contactTextPositions.rotation}
                    scale={contactTextPositions.scale}
                    onPointerOver={() => {
                        setMousePointerIcon(true);
                        deEmphasiseRestAnimation(contactText);
                    }}
                    onPointerLeave={() => {
                        setMousePointerIcon(false);
                        reEmphasiseAllAnimation();
                    }}
                    onClick={() => handleNavigationClick('/contact')}
                >
                    / contact
                </Text>
        </>
    );
};

export default CanvasSection;
