import modelTransforms from './data/MODEL_TRANSFORMS.json';

const convertDegreeToRadians = (degree) => degree * (Math.PI / 180);
const convertRadiansToDegree = (radians) => radians * (180 / Math.PI);
const convertArrayToRadians = (degreeArray) => degreeArray.map(degree => convertDegreeToRadians(degree));

const extractPositions = (section, subSection) => ({
    position: modelTransforms[section][subSection].position,
    scale: modelTransforms[section][subSection].scale,
    rotation: convertArrayToRadians(modelTransforms[section][subSection].rotation)
});

export const precomputedRoomPositions = {
    canvasSection: extractPositions('roomModel', 'canvasSection'),
    projectsAndExperiencesSection: extractPositions('roomModel', 'projectsAndExperiencesSection'),
}

export const precomputedCameraPositions = {
    '/': extractPositions('cameraPositions', '/'),
    '/projects': extractPositions('cameraPositions', '/projects'),
    '/experiences': extractPositions('cameraPositions', '/experiences'),
    '/contact': extractPositions('cameraPositions', '/contact'),
    'intermediary': extractPositions('cameraPositions', 'intermediary')
};

export const precomputedCanvasPositions = {
    SeanText: extractPositions('canvasSection', 'SeanText'),
    dateText: extractPositions('canvasSection', 'dateText'),
    portfolioText: extractPositions('canvasSection', 'portfolioText'),
    _Text: extractPositions('canvasSection', '_Text'),
    projectsText: extractPositions('canvasSection', 'projectsText'),
    experiencesText: extractPositions('canvasSection', 'experiencesText'),
    contactText: extractPositions('canvasSection', 'contactText')
};

export const precomputedProjectsPositions = {
    projectsText: extractPositions('projectsSection', 'projectsText'),
    worldMap: extractPositions('projectsSection', 'worldMap'),
    plane: extractPositions('projectsSection', 'plane'),
    arcadeMachine: extractPositions('projectsSection', 'arcadeMachine'),
    inventoryManagement: extractPositions('projectsSection', 'inventoryManagement'),
    screen: extractPositions('projectsSection', 'screen'),
    whiteScreen: extractPositions('projectsSection', 'whiteScreen'),
    whiteScreenSize: modelTransforms.projectsSection.whiteScreen.planeArguments,
    titleText: extractPositions('projectsSection', 'titleText'),
    descriptionStartingText: extractPositions('projectsSection', 'descriptionStartingText'),
    descriptionStartingTextParagraphDistance: modelTransforms.projectsSection.descriptionStartingText.perParagraphDistance,
    skillsetText: extractPositions('projectsSection', 'skillsetText'),
    skillsetItem: extractPositions('projectsSection', 'skillsetItem'),
    skillsetItemParagraphDistance: modelTransforms.projectsSection.skillsetItem.perParagraphDistance,
    languagesText: extractPositions('projectsSection', 'languagesText'),
    languagesItem: extractPositions('projectsSection', 'languagesItem'),
    languagesItemParagraphDistance: modelTransforms.projectsSection.languagesItem.perParagraphDistance,
    viewProjectArrowText: extractPositions('projectsSection', 'viewProjectArrowText'),
    viewProjectLabelTextOne: extractPositions('projectsSection', 'viewProjectLabelTextOne'),
    viewProjectLabelTextTwo: extractPositions('projectsSection', 'viewProjectLabelTextTwo')
};

export const precomputedExperiencesPositions = {
    car: {
        positions: modelTransforms.experiencesSection.car.positions,
        scale: modelTransforms.experiencesSection.car.scale,
        rotation: convertArrayToRadians(modelTransforms.experiencesSection.car.rotation)
    },
    tabletopAndObjects: extractPositions('experiencesSection', 'tabletopAndObjects')
};

export const precomputedContactPositions = {
    multilineText: {
        rotation: convertArrayToRadians(modelTransforms.contactSection.multilineText.rotation)
    }
};